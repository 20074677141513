<template>
  <div class="">
    <Breadcrumbs title="Class Registry" />
    <div class="container-fluid">
      <b-row>
        <b-col cols="12">
          <div class="card">
            <div class="card-body">
              <div class="form-row">
                <div class="col-md-2 col-lg-3">
                  <b-form-group label="Institute *">
                    <b-form-select @change="searchClassDetail" class="form-control border" size="lg" v-model="institute_id" :options="user.institutes" value-field="id" text-field="name" required>
                      <template #first>
                        <b-form-select-option :value="null" selected="selected">-- All Institutes --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-6 col-lg-7">
                  <b-form-group label="Class">
                    <b-form-select :disabled="!institute_id" @change="fetchData" :options="class_details" v-model="class_detail_id" text-field="text" value-field="id" class="form-control border" size="lg">

                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-2 col-lg-2 col-xl-2">
                  <b-button class="btn btn-block btn-square btn-primary btn-lg m-t-30" @click="clearForm" :disabled="class_detail_id===null">CLEAR</b-button>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="card">
            <div class="card-header"><h5>Assigned Students</h5></div>
            <div class="card-body">

              <div class="row">
                <div class="m-t-6 col-12 col-md-6">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Type to Search" class="border btn-square" autofocus></b-form-input>
<!--                    <TableSearchFilter placeholder="Type to Search" label="Student NIC *" v-on:updateFilter="updateFilter" class="border btn-square" />-->
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" class="btn btn-square border" variant="primary">Clear</b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-button @click="fetchData" class="btn btn-square border" variant="default">Refresh</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="m-t-10 col-12 col-md-6 text-md-right">
                  <b-form-group label-cols="9" label="Per Page">
                    <b-form-select class="btn-square border text-center" v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </div>
              </div>


              <div class="table-responsive">
                <b-table
                    ref="student_list"
                    show-empty
                    :items="tableRecords"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="1"
                    :per-page="perPage"
                    @filtered="onFiltered"
                    @row-selected="rowSelected"
                    selected-variant="success"
                >
                  <template #cell(action)="row" class="text-right">
                    <b-button-group>
                      <b-button class="btn-square mr-1 ml-1" variant="primary" @click="markAttendance(row.index, row.item, $event.target)" title="Add Free Card">Remove</b-button>
                    </b-button-group>
                  </template>
                </b-table>
              </div>

              <b-row >
                <b-col cols="4">
                  Records: <strong>{{ totalRows }}</strong>
                </b-col>
                <b-col cols="8" class="my-1" v-if="totalRows">
                  <b-pagination
                      align="right"
                      class="my-0"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      pills
                  ></b-pagination>
                </b-col>
              </b-row>

            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// import TableSearchFilter from "@/components/table_search_field";
import {mapState} from "vuex";
import CRUD_API from '@/services/crud'
// import moment from 'moment'
import API from "@/services/api";
import {checkUserPermissions} from "@/plugins/functions";
export default {
  name: "class_registry",
  // components: { TableSearchFilter },
  computed: {
    ...mapState({ user: state => JSON.parse(state.auth.user) }),
  },
  watch: {
    currentPage() {
      this.fetchData()
    },
    perPage() {
      this.currentPage = 1
      this.fetchData()
    },
    filter() {
      this.currentPage = 1
      this.fetchData()
    }
  },
  mounted() {
    this.searchClassDetail()
  },
  data(){
    return {
      filter: '',
      totalRows: 0,
      perPage: 5,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      selected: [],
      tableRecords: [],
      tableFields: [
        // { key: 'id', label: 'ID', sortable: false },
        { key: 'student.nic', label: 'NIC', sortable: true },
        { key: 'student.username', label: 'Username', sortable: true },
        { key: 'student.first_name', label: 'First Name', sortable: true },
        { key: 'student.last_name', label: 'Last Name', sortable: true },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],

      institute_id: null,
      creator_id: null,
      class_detail_id: null,
      class_details: [],
    }
  },
  methods: {
    checkUserPermissions,
    onFiltered(filteredItems) {
      this.currentPage = 1
      this.items.count = filteredItems.length
    },
    rowSelected(item) {
      this.selected = item
    },
    clearSearch() {
      this.filter = ''
      this.currentPage = 1
      this.from_date = null
      this.to_date = null
      this.fetchData()
    },
    handlePageChange(value) {
      //alert(value)
      this.perPage = value
      this.fetchData()
    },
    handlePageSizeChange(value) {
      this.currentPage = value
      this.fetchData()
    },
    updateFilter(value) {
      this.filter = value
      this.fetchData()
    },
    async fetchData() {
      this.tableRecords = []
      await CRUD_API.index('/api/backend/pages/class-registry', {
        params: {
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter,
          institute_id: this.institute_id,
          class_detail_id: this.class_detail_id
        }
      }).then((result) => {
        this.totalRows = result.data.data.total
        this.tableRecords = result.data.data.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.$refs.marker_table.refresh()
      })
    },
    clearForm() {
      this.class_detail_id = null
      this.institute_id = null
      this.class_details = []
      this.tableRecords = []
    },
    async searchClassDetail() {
      this.class_details = []
      this.class_detail_id = null
      this.tableRecords = []
      await API.select('classes', {
        institute_id: this.institute_id
      }).then((result) => {
        this.class_details = result.data.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => this.class_details.unshift({ value: null, text: "All Classes" }))
    },
    markAttendance() {},
  }
}
</script>

<style scoped>

</style>